import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/san-diego-home-buying-your-guide-to-closing-costs"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_Homebuying in San Diego_ Everything You Need to Know About Closing Costs.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                We know from personal experience how easy it is to get caught up in the excitement
                of purchasing a home and overlook some of the less glamorous aspects of the process.
              </CommonParagraph>
              <CommonParagraph>
                While most buyers know they must produce a substantial downpayment if they take the
                conventional loan route, many are caught off guard by closing costs. These fees can
                add up, leaving you scrambling if you’re unprepared.
              </CommonParagraph>
              <CommonParagraph>
                That’s where we come in. We want to ensure you walk into the closing day with
                confidence and peace of mind. Here’s everything you need to know about closing costs
                when buying a home in San Diego.
              </CommonParagraph>
              <Heading type="h2">Closing Costs: What Are They?</Heading>
              <CommonParagraph>
                Closing costs are expenses buyers and sellers pay when finalizing a purchase. As you
                may have already gathered, these expenses are separate from the down payment and are
                due on the day you close.
              </CommonParagraph>
              <CommonParagraph>
                If you’re a buyer who wants to get a jump-start on calculating closing costs, they
                are typically between 2% and 3% of the home’s purchase price. This number can range
                from 5% to 8% of the sale price for sellers. This number may vary depending on the
                lender, location, property taxes, etc. If you want to nail down a more accurate
                estimate,{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-real-estate-agents"
                  text="our agents"
                />{" "}
                will work with you to ensure there are not any surprises.
              </CommonParagraph>
              <Heading type="h2">Who Pays Closing Costs?</Heading>
              <CommonParagraph>
                In San Diego, both buyers and sellers pay closing costs. Buyers generally pay for
                items related to their loan: origination fees, appraisal fees, etc. On the other
                hand, sellers typically cover real estate commissions and transfer taxes.{" "}
              </CommonParagraph>
              <CommonParagraph>
                That said, every real estate transaction is unique. In some cases, buyers and
                sellers may negotiate to share closing costs or negotiate a deal where one party
                agrees to cover specific expenses in exchange for concessions.
              </CommonParagraph>
              <Heading type="h2">The Specifics: What’s Included in Closing Costs</Heading>
              <CommonParagraph>
                <b>Lender’s Title Insurance:</b> Lenders require buyers to purchase title insurance,
                which protects them against any title defects or disputes arising over questions
                about ownership.
              </CommonParagraph>
              <CommonParagraph>
                <b>Escrow Fees:</b> When buyers and sellers enter into an agreement, they often use
                an escrow company (a neutral third party) to facilitate the transaction by holding
                funds and essential documents. The fee may vary but is based on the home’s purchase
                price—typically $2 per $1,000 + a $250 base fee.
              </CommonParagraph>
              <CommonParagraph>
                <b>Property Taxes:</b> Buyers will also be expected to pay prorated property taxes
                from the closing date to the end of the tax period.
              </CommonParagraph>
              <CommonParagraph>
                <b>Homeowner’s Insurance:</b> Lenders will want proof that you have a homeowner’s
                insurance policy. Premiums vary depending on the insurance company.
              </CommonParagraph>
              <CommonParagraph>
                <b>HOA Fees: </b>If your new home is part of a homeowners association, you must pay
                prorated HOA fees. These fees vary with the type of community, location, and
                amenities.
              </CommonParagraph>
              <CommonParagraph>
                <b>Lender Origination Fees:</b> Lenders typically charge these fees to cover the
                cost of verifying your financial information and processing your application.
              </CommonParagraph>
              <CommonParagraph>
                <b>Appraisals:</b> Appraisals tell lenders the market value of a property to ensure
                it is proportionate to the amount of money they are extending to you.
              </CommonParagraph>
              <CommonParagraph>
                <b>Home Inspections:</b> Inspections can reveal underlying problems with the
                property, giving you more negotiating power and peace of mind. It’s worth noting
                that these fees are generally paid before closing—directly to the inspector at the
                time of service.
              </CommonParagraph>
              <Heading type="h2">Your Real Estate Goals Are Our Priority!</Heading>
              <CommonParagraph>
                Whether you have questions about closing costs or are looking for strategies to
                reduce them,
                <BlogLink url="https://selbysellssd.com/" text="The Selby Team" /> can help. Whether
                you are a{" "}
                <BlogLink
                  url="https://selbysellssd.com/investing-in-rental-properties-in-san-diego/"
                  text="seasoned investor"
                />{" "}
                or a first-time buyer, our agents will guide you through the San Diego real estate
                market and make your home buying journey as smooth as possible.{" "}
                <ContactSlideoutLink text="Contact us today" /> to learn more about how we can help
                you achieve your homeownership goals!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
